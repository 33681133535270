<template>
  <v-autocomplete
    v-model="brandId"
    :label="$t('brands')"
    :items="brands"
    item-value="id"
    item-text="name"
    outlined
    dense
    @change="onSelect"
  >
    <template v-slot:item="data">
      <template v-if="!data.item.preview_image">
        <v-list-item-content v-text="data.item.name"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-avatar>
          <img :src="data.item.preview_image" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.name"></v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import { Brand, BrandCollection } from "@planetadeleste/vue-mc-shopaholic";
import { Vue, Component, VModel } from "vue-property-decorator";

@Component
export default class BrandsSelect extends Vue {
  @VModel({ type: Number, default: null }) brandId!: number;
  brands: Brand[] = [];

  mounted() {
    this.$nextTick(this.loadBrands);
  }

  onSelect(iValue: number) {
    this.$emit("input", iValue);
    this.$emit("change", iValue);
  }

  async loadBrands() {
    const obBrands = new BrandCollection();
    await obBrands.fetch();
    this.brands = obBrands.isEmpty() ? [] : obBrands.models;
  }
}
</script>
